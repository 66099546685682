<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-template-shadow -->
<template>
  <v-card>
    <v-card-title>{{ $t('menu.translate') }} / {{ $t('menu.perfilUser') }} / {{ $t('lbl.description') }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            color="error"
            class="mb-4 me-3"
            @click="$router.push({ name: 'translate-perfils' })"
          >
            <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
            <span>{{ $t('btn.back') }}</span>
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="primary"
            class="mb-4 me-3"
            :loading="loading"
            @click="save()"
          >
            <span>{{ $t('btn.update') }}</span>
            <v-icon>{{ icons.mdiUpdate }}</v-icon>
          </v-btn>
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="2"
        >
          <span
            v-if="character_limit"
            style="font-size: 10px;"
          >
            <i>
              {{ `${character_count} / ${character_limit}` }}
            </i>
          </span>
          <v-btn
            v-if="!$store.state.app.onlyShow"
            color="success"
            class="mb-4 me-3"
            :loading="loadingTranslate"
            :disabled="disabledTranslate"
            @click="translate"
          >
            <span>{{ $t('btn.translate') }}</span>
            <v-icon>mdi-translate</v-icon>
          </v-btn>
        </v-col>
      </v-row>

      <v-form class="multi-col-validation">
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="item.description"
              label="Español"
              outlined
              rows="3"
              row-height="25"
              shaped
              @input="chengeCantTranslate"
            ></v-textarea>
          </v-col>
          <v-col
            v-for="(lang, indL) in languages"
            :key="indL"
            cols="12"
            md="6"
          >
            <v-textarea
              v-model="item[`description_${lang.locale}`]"
              :label="lang.name"
              outlined
              rows="3"
              row-height="25"
              shaped
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  TiptapVuetify,
  Bold,
  Italic,
  BulletList,
  OrderedList,
  ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    TiptapVuetify,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
        mdiInformationOutline,
      },
      extensions: [BulletList, OrderedList, ListItem, Italic, Bold, HardBreak],
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      item: {},
      showMap: true,
      loading: false,
      loadingTranslate: false,
      permisos: localStorage.getItem('permisos_auth'),
      disabledTranslate: true,
      character_count: null,
      character_limit: null,
      cant_translate: 0,
      languages: [],
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('tutorials-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      onlyShow: state => state.app.onlyShow,
    }),
  },
  mounted() {
    this.getLimit()
  },
  created() {
    if (localStorage.getItem('tutorials-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    this.getItem()
  },
  methods: {
    ...mapMutations(['setOnlyShow']),
    chengeCantTranslate() {
      this.cant_translate = this.item.description.length * this.languages.length
      if (this.cant_translate > this.character_limit) {
        this.disabledTranslate = true
      } else if (this.character_limit > 0) {
        this.disabledTranslate = false
      }
    },
    async getLimit() {
      this.disabledTranslate = true
      this.character_count = null
      this.character_limit = null
      await this.axios
        .post('traslate/get-limit', { }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success) {
            this.character_count = res.data.data.character_count
            this.character_limit = res.data.data.character_limit
            if (res.data.data.character_count < res.data.data.character_limit) {
              this.disabledTranslate = false
            }
          }
        })

      // .finally(() => this.chengeCantTranslate())
    },
    async getItem() {
      if (localStorage.getItem('traslate-perfil-id') !== null) {
        const id = localStorage.getItem('traslate-perfil-id')
        await this.axios
          .get(`perfil_afiliado/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success) {
              this.item = res.data.data.data
              this.languages = res.data.data.languages
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
    async save() {
      if (localStorage.getItem('traslate-perfil-id') !== null) {
        await this.axios
          .post('perfil_afiliado_translate/update-translate-description', this.item, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) { } else {
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => this.reseat())
      }
    },
    async translate() {
      if (this.item.description) {
        this.loadingTranslate = true
        await this.axios
          .post('perfil_afiliado_translate/translate-description', this.item, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
              this.$toast.error(response.data.message)
            } else {
              this.getLimit()
              this.item = response.data.data.item
              this.$toast.success(this.$t('msg.infoSuccess'))
            }
          })
          .finally(() => {
            this.loadingTranslate = false
          })
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    reseat() {
      this.loading = false
      this.loadingTranslate = false

      this.item = {}
      localStorage.removeItem('traslate-perfil-id')
      this.$router.push({ name: 'translate-perfils' })
    },
  },
}
</script>
